import React from "react";
import ReactMarkdown from "react-markdown";
import { Route, Switch } from "react-router";
import { BrowserRouter } from "react-router-dom";
import { getBlogPostFromUrl, Post, PostType, posts } from "./BlogPosts";
import {
  GlobalStyle,
  Center,
  Spacer,
  VerySmall,
  TitleBar,
  Link,
  Bold,
  PageContent,
  SponsorImgContainer,
  SponsorsContainer,
  MonkeyContainer,
  Logo,
  LogoWithText,
  Monkey,
} from "./Components";
import rehypeRaw from "rehype-raw";
// @ts-ignore
import headingId from "remark-heading-id";
import remarkGfm from "remark-gfm";
import styled from "styled-components";

/**
 * This is the root of the application.
 */
export function App() {
  return (
    <>
      <GlobalStyle />
      <BrowserRouter>
        <Switch>
          <Route path="/" exact component={LandingPage} />
          <Route path="*" component={FourOhFour} />
        </Switch>
      </BrowserRouter>
    </>
  );
}

const TitleImage = styled.img`
  max-width: 100%;
  width: 600px;
  max-width: 100%;
  box-sizing: border-box;
  overflow: hidden;

  @media only screen and (max-width: 1000px) {
    width: unset;
    padding-left: 48px;
    padding-right: 48px;
  }
`;

/**
 * Landing page of 0xPARC website, contains links to other pages.
 */
function LandingPage() {
  return (
    <Center>
      <Spacer height={3} />

      <TitleImage src={require("../public/static/logo.jpeg")}></TitleImage>

      <Spacer height={1} />

      <div style={{ textAlign: "center" }}>
        <a href="https://nonchalant-anglerfish-4e2.notion.site/D-E-F-CON-Schedule-April-19-2022-44394819ebe04f8191711791d647f00e">
          Schedule
        </a>{" "}
        <br />
        <a href="https://g.page/transformatorhuis-westergas?share">
          Transformatorhuis
        </a>
        , Amsterdam <br />
        April 19th 2022 <br />
      </div>

      <PageContent>
        <p>
          At 0xPARC, we believe that Ethereum will change gaming, for reasons
          that go far beyond money and tokens.
        </p>
        <p>
          We believe that crypto-native games — games that run entirely on-chain
          — will turn massively multiplayer games into fully-fledged digital
          worlds that outlast their creators. These worlds will be owned by
          their players — not just financially, but creatively, too, as players
          are empowered to build and govern both the mechanics and communities
          of the games they play. And they’ll be able to leverage the power of
          permissionless interoperability, allowing players to remix game rules
          and more, taking the creative modding culture that’s brought us
          genre-defining games like Minecraft, Team Fight Tactics, and DayZ to
          new levels.
        </p>

        <p>The revolution has quietly begun.</p>

        <p>
          Dark Forest is the world’s first fully decentralized MMO real-time
          strategy game, running entirely on-chain. Players explore an infinite,
          procedurally-generated universe with zkSNARKs: expanding their
          empires, engaging in diplomacy and war with other players, building
          smart contract AIs, and trading planets, artifacts, zero-knowledge
          proofs, and even CPU hashpower. Dark Forest is a living ecosystem
          where player corporations, DAOs, automated bots, and smart contracts
          go head-to-head, with the fate of a universe in their hands.
        </p>

        <p>
          Ember is an unannounced Civilization/Dwarf Fortress hybrid running
          fully on-chain — powered by Lattice. A world where smart contracts own
          countries and run unstoppable guilds of mercenaries, where Uniswap
          pools can be raided and looted, where strategies can be sold
          permisionlessly on-chain. A world where the players can do engineering
          without being limited by the game engine. Where there is no
          “god-mode”, and where developers and players play by the same rules.
        </p>
        <p>
          We are excited to bring these and other projects together for a full
          day of talks, workshops, hackathons, and game jam. Expect to go back
          home late at night with your head spinning with ideas. The virtual
          worlds of the future might be instigated that same night in Amsterdam.
        </p>
        <Spacer height={15} />
      </PageContent>

      <MonkeyContainer>
        <Monkey />
      </MonkeyContainer>
    </Center>
  );
}

/**
 * Content rendered when there's no page that matches the URL which the user has navigated to.
 */
function FourOhFour() {
  return (
    <>
      <Spacer height={6} />
      <Center style={{ fontSize: "10em", color: "#aaa" }}>404</Center>
      <Center>
        <Link href="/">home</Link>
      </Center>
    </>
  );
}
